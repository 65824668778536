import React from 'react';
import LayoutContainer from './../components/layout';
import { Trans, withTranslation } from "react-i18next"

const TermsOfService = () => {
  return (
    <LayoutContainer seoTitle="Terms of service">
      <article>
        <div className="row">
          <div className="col l8 offset-l2 s12">
            <h1 className="big-title uppercase center-align">
              <Trans i18nKey={"termsofservice.title"} />
            </h1>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam etiam erat velit scelerisque in dictum. Sapien nec sagittis aliquam malesuada. Vel quam elementum pulvinar etiam non quam lacus suspendisse. Leo a diam sollicitudin tempor id eu. Id cursus metus aliquam eleifend. Nunc aliquet bibendum enim facilisis. Pretium fusce id velit ut tortor pretium viverra. Et ultrices neque ornare aenean euismod elementum nisi quis. Urna et pharetra pharetra massa massa ultricies mi quis.<br /><br />

              Rutrum quisque non tellus orci ac auctor augue. Ornare suspendisse sed nisi lacus sed viverra tellus in hac. Platea dictumst vestibulum rhoncus est pellentesque elit. Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Quam elementum pulvinar etiam non quam. Pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Aliquam sem et tortor consequat id porta nibh. Morbi tempus iaculis urna id. Etiam sit amet nisl purus in mollis.<br /><br />

              Velit dignissim sodales ut eu sem integer vitae justo eget. Gravida rutrum quisque non tellus orci ac auctor augue mauris. Non odio euismod lacinia at quis risus sed vulputate odio. Nisi est sit amet facilisis magna etiam. Rhoncus urna neque viverra justo nec ultrices. Purus semper eget duis at tellus. Rhoncus urna neque viverra justo nec. Amet mauris commodo quis imperdiet massa tincidunt nunc. Sit amet nulla facilisi morbi tempus iaculis urna id volutpat. Sit amet risus nullam eget felis eget nunc. Suspendisse ultrices gravida dictum fusce ut. Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at augue eget. Orci ac auctor augue mauris augue neque. Bibendum neque egestas congue quisque egestas. Mi proin sed libero enim sed faucibus. Quam adipiscing vitae proin sagittis nisl rhoncus. Magnis dis parturient montes nascetur ridiculus. Habitant morbi tristique senectus et. Tristique risus nec feugiat in fermentum. Cursus eget nunc scelerisque viverra mauris.<br /><br />

              Ac turpis egestas maecenas pharetra. Pretium fusce id velit ut tortor pretium viverra suspendisse. Orci nulla pellentesque dignissim enim sit amet venenatis urna. Aliquam ultrices sagittis orci a scelerisque purus semper eget. Faucibus scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam. Sit amet consectetur adipiscing elit ut aliquam. Aliquam ultrices sagittis orci a scelerisque purus. Ut eu sem integer vitae justo eget magna. Aliquam eleifend mi in nulla posuere sollicitudin. Posuere ac ut consequat semper viverra nam libero. In est ante in nibh mauris cursus mattis molestie a. Velit euismod in pellentesque massa placerat duis ultricies lacus. Aliquet eget sit amet tellus cras. Fringilla urna porttitor rhoncus dolor.<br /><br />

              Scelerisque fermentum dui faucibus in. Facilisis leo vel fringilla est. Platea dictumst quisque sagittis purus sit amet volutpat consequat. Massa massa ultricies mi quis hendrerit. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Amet nulla facilisi morbi tempus iaculis urna id volutpat. Tellus in hac habitasse platea dictumst. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac. Id faucibus nisl tincidunt eget nullam non. Habitasse platea dictumst quisque sagittis purus. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Molestie at elementum eu facilisis sed odio.
            </p>
          </div>
        </div>
      </article>
    </LayoutContainer>
  );
}

export default withTranslation()(TermsOfService);